import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

import PICTO_EYE from '../assets/images/picto-eye.svg';

function InscriptionStep2Page() {
    const { currentUser, login } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [pseudo, setPseudo] = useState('');
    const [password, setPassword] = useState('');
    const [passwordBis, setPasswordBis] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        socket.on('server:client:inscription-step-2', (data) => {
            if (data.error) {
                setErrorMessage(data.error);
            }
            else {
                localStorage.setItem('pseudo', pseudo.trim());
                localStorage.setItem('password', password.trim());
                localStorage.setItem('hasVisited', '1');

                login(data);

                setRedirect(true);
            }
        });

        return () => {
            socket.off('server:client:inscription-step-2');
        };
    }, [pseudo, password]);

    const _submit = () => {
        setErrorMessage('');

        if (pseudo.trim() === '') {
            setErrorMessage('Veuillez renseigner votre pseudo');
        }
        else if (password.trim() === '') {
            setErrorMessage('Veuillez renseigner votre mot de passe');
        }
        else if (password !== passwordBis) {
            setErrorMessage('Veuillez renseigner votre mot de passe');
        }
        else {
            const user = { uuid: currentUser.uuid, pseudo: pseudo.trim(), password: password.trim() };

            socket.emit('client:server:inscription-step-2', user);
        }
    };

    const _togglePassword = () => {
        if (passwordFieldType === 'text') {
            setPasswordFieldType('password');
        }
        else {
            setPasswordFieldType('text');
        }
    };

    if (redirect || (currentUser && currentUser.snowmobile)) {
        return <Navigate to='/inscription/step-3' />;
    }

    return (
        <Layout>
            <main className='inscription'>
                <div className='popin'>
                    <div className='title'>Choisir un pseudo</div>

                    <input type='text' className='pseudo' placeholder='Pseudo' value={pseudo} onChange={(e) => setPseudo(e.target.value)} autoComplete='off' />

                    <div className='title'>Choisir un mot de passe</div>

                    <div className='password-container'>
                        <input type={passwordFieldType} className='password' placeholder='Mot de passe' value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='off' />

                        <img src={PICTO_EYE} width={17} onClick={_togglePassword} />
                    </div>

                    <div className='title'>Confirmer le mot de passe</div>

                    <div className='password-container'>
                        <input type={passwordFieldType} className='password-bis' placeholder='Mot de passe' value={passwordBis} onChange={(e) => setPasswordBis(e.target.value)} autoComplete='off' />

                        <img src={PICTO_EYE} width={17} onClick={_togglePassword} />
                    </div>

                    {errorMessage !== '' &&
                        <div className='txt-error text-danger'>{errorMessage}</div>
                    }

                    <button onClick={_submit}>Suivant</button>
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default InscriptionStep2Page;
