import React from 'react';

import Layout from '../components/Layout';

function NotFoundPage() {
    return (
        <Layout>
            <main className='d-flex justify-content-center align-items-center'>
                <h1>Page Not Found</h1>
            </main>
        </Layout>
    );
}

export default NotFoundPage;
