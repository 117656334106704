import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import BigSnowmobile from '../components/BigSnowmobile';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

function InscriptionStep3Page() {
    const { currentUser, login } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [idSnowmobile, setIdSnowmobile] = useState(null);
    const [idSnowmobileValide, setIdSnowmobileValide] = useState(false);
    const [colorSnowmobile, setColorSnowmobile] = useState(null);
    const [colorSnowmobileValide, setColorSnowmobileValide] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        socket.on('server:client:inscription-step-3', (data) => {
            if (data.error) {
                setErrorMessage(data.error);
            }
            else {
                login(data);
            }
        });

        return () => {
            socket.off('server:client:inscription-step-3');
        };
    }, []);

    const _submitId = () => {
        setErrorMessage('');

        if (idSnowmobile === null) {
            setErrorMessage('Veuillez renseigner votre catamaran');
        }
        else {
            setIdSnowmobileValide(true);

            switch (idSnowmobile) {
                case '1':
                    setColorSnowmobile('bleu');
                    break;

                case '2':
                    setColorSnowmobile('jaune');
                    break;

                case '3':
                    setColorSnowmobile('rouge');
                    break;
            }
        }
    };

    const _submitColor = () => {
        setErrorMessage('');

        if (colorSnowmobile === null) {
            setErrorMessage('Veuillez renseigner votre couleur');
        }
        else {
            setColorSnowmobileValide(true);
        }
    };

    const _submit = () => {
        setErrorMessage('');

        const user = { uuid: currentUser.uuid, snowmobile: `${idSnowmobile}-${colorSnowmobile}` };

        socket.emit('client:server:inscription-step-3', user);
    };

    const _reset = () => {
        setErrorMessage('');
        setIdSnowmobile(null);
        setIdSnowmobileValide(false);
        setColorSnowmobile(null);
        setColorSnowmobileValide(false);
    };

    if (currentUser.snowmobile) {
        return <Navigate to='/' />;
    }

    if (!currentUser.isActive) {
        return <Navigate to='/validate-account' />;
    }

    if (currentUser.email === currentUser.pseudo) {
        return <Navigate to='/inscription/step-2' />;
    }

    return (
        <Layout>
            <main className='inscription'>
                <div className='popin'>
                    {idSnowmobileValide === false &&
                        <>
                            <div className='title'>Choisir un catamaran</div>

                            <input type='radio' checked={idSnowmobile === '1'} onChange={(e) => setIdSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-1' value='1' />

                            <label htmlFor='snowmobile-1'>
                                <BigSnowmobile id={1} color={idSnowmobile === '1' ? 'selection' : 'blanc'} />
                            </label>

                            <input type='radio' checked={idSnowmobile === '2'} onChange={(e) => setIdSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-2' value='2' />

                            <label htmlFor='snowmobile-2'>
                                <BigSnowmobile id={2} color={idSnowmobile === '2' ? 'selection' : 'blanc'} />
                            </label>

                            <input type='radio' checked={idSnowmobile === '3'} onChange={(e) => setIdSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-3' value='3' />

                            <label htmlFor='snowmobile-3'>
                                <BigSnowmobile id={3} color={idSnowmobile === '3' ? 'selection' : 'blanc'} />
                            </label>

                            {errorMessage !== '' &&
                                <div className='txt-error text-danger'>{errorMessage}</div>
                            }

                            <button onClick={_submitId}>Suivant</button>
                        </>
                    }

                    {idSnowmobileValide === true && colorSnowmobileValide === false &&
                        <>
                            <div className='title'>Choisir un catamaran</div>

                            <div className='preview-snowmobile'>
                                <BigSnowmobile id={idSnowmobile} color={colorSnowmobile} />
                            </div>

                            <div className='colors'>
                                <input type='radio' checked={colorSnowmobile === 'bleu'} onChange={(e) => setColorSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-bleu' value='bleu' />
                                <label htmlFor='snowmobile-bleu' className='color-snowmobile' style={{ backgroundColor: '#001dff' }} />

                                <input type='radio' checked={colorSnowmobile === 'vert'} onChange={(e) => setColorSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-vert' value='vert' />
                                <label htmlFor='snowmobile-vert' className='color-snowmobile' style={{ backgroundColor: '#18bf02' }} />

                                <input type='radio' checked={colorSnowmobile === 'jaune'} onChange={(e) => setColorSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-jaune' value='jaune' />
                                <label htmlFor='snowmobile-jaune' className='color-snowmobile' style={{ backgroundColor: '#ffeb00' }} />

                                <input type='radio' checked={colorSnowmobile === 'orange'} onChange={(e) => setColorSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-orange' value='orange' />
                                <label htmlFor='snowmobile-orange' className='color-snowmobile' style={{ backgroundColor: '#f7b500' }} />

                                <input type='radio' checked={colorSnowmobile === 'rouge'} onChange={(e) => setColorSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-rouge' value='rouge' />
                                <label htmlFor='snowmobile-rouge' className='color-snowmobile' style={{ backgroundColor: '#e02020' }} />

                                <input type='radio' checked={colorSnowmobile === 'violet'} onChange={(e) => setColorSnowmobile(e.target.value)} name='snowmobile' id='snowmobile-violet' value='violet' />
                                <label htmlFor='snowmobile-violet' className='color-snowmobile' style={{ backgroundColor: '#b620e0' }} />
                            </div>

                            {errorMessage !== '' &&
                                <div className='txt-error text-danger'>{errorMessage}</div>
                            }

                            <button onClick={_submitColor}>Suivant</button>
                        </>
                    }

                    {idSnowmobileValide === true && colorSnowmobileValide === true &&
                        <>
                            <div className='title'>Choisir un catamaran</div>

                            <div className='preview-snowmobile'>
                                <BigSnowmobile id={idSnowmobile} color={colorSnowmobile} />
                            </div>

                            {errorMessage !== '' &&
                                <div className='txt-error text-danger'>{errorMessage}</div>
                            }

                            <button onClick={_submit}>Valider</button>

                            <button onClick={_reset} className='reset'>Modifier</button>
                        </>
                    }
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default InscriptionStep3Page;
