import React, { createContext, useState } from 'react';
import { io } from 'socket.io-client';

import { SOCKETIO_URL } from '../utils/config';

const SocketContext = createContext();

const socketIO = io(SOCKETIO_URL);

function SocketProvider({ children }) {
    const [socket] = useState(socketIO);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
}

export { SocketContext, SocketProvider };