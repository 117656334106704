import React, { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import moment from 'moment';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';

import PICTO_SCOUT from '../assets/images/picto-scout-neige.svg';
import PICTO_RANKING from '../assets/images/picto-classement.svg';
import PICTO_ECLAIR from '../assets/images/picto-eclair.svg';
import PICTO_HORLOGE from '../assets/images/picto-horloge.svg';

function TopBar() {
    const { currentUser, logout } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [myRank, setMyRank] = useState(0);

    useEffect(() => {
        setTimeout(function() {
            socket.on('server:client:my-rank', (data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setMyRank(data);
                }
            });

            socket.emit('client:server:my-rank', { uuid: currentUser.uuid, step: currentUser.currentStep });
        }, 100);

        return () => {
            socket.off('server:client:my-rank');
        };
    }, []);

    const now = new moment();
    const today = now.format('YYYY-MM-DD');
    const startAt = new moment(`${today} ${currentUser.gameTime.startAt}`);
    const diff = now.diff(startAt, 'seconds');
    const countdownMoment = now.add(currentUser.gameTime.duration, 'seconds').subtract(diff, 'seconds');
    const countdown = countdownMoment.valueOf();

    return (
        <div className='top'>
            <div className='profile'>
                <div className='username'>
                    <img src={PICTO_SCOUT} width={25} />

                    <span>{currentUser.pseudo}</span>
                </div>

                <div className='ranking'>
                    <img className='picto-rank' src={PICTO_RANKING} width={16} />

                    <span>{myRank}e</span>

                    <img src={PICTO_ECLAIR} width={11} />
                </div>
            </div>

            <div className='timer-container'>
                <div className='timer'>
                    <img src={PICTO_HORLOGE} width={14} />

                    <Countdown
                        date={countdown}
                        renderer={({ minutes, seconds, completed }) => {
                            if (completed) {
                                logout();

                                return <span>'00:00'</span>;
                            } else {
                                return <span>{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span>;
                            }
                        }}
                    />
                </div>

                <div className='label-timer'>
                    Temps restant<br /> aujourd’hui
                </div>
            </div>
        </div>
    );
}

export default TopBar;
