import React, { createContext, useContext, useState } from 'react';

import { SocketContext } from './SocketContext';

const CurrentUserContext = createContext();

function CurrentUserProvider(props) {
    const { socket } = useContext(SocketContext);

    const [currentUser, setCurrentUser] = useState(null);

    const isAuth = () => {
        return currentUser != null;
    };

    const login = (user, callback = null) => {
        if (user.gameTime.duration > 0) {
            setCurrentUser(user);

            callback && callback(null);
        }
        else {
            callback && callback('Vous avez épuisé votre temps journalier');
        }
    };

    const logout = () => {
        socket.emit('client:server:end-session');

        setCurrentUser(null);

        localStorage.removeItem('pseudo');
        localStorage.removeItem('password');
    };

    const truncatePseudo = (maxLength) => {
        if (currentUser.pseudo.length > maxLength) {
            return currentUser.pseudo.slice(0, maxLength) + '...';
        }
        else {
            return currentUser.pseudo;
        }
    };

    const updatePlayer = (player) => {
        setCurrentUser({
            ...currentUser,
            ...player
        });
    };

    return (
        <CurrentUserContext.Provider value={{ currentUser, setCurrentUser, isAuth, login, logout, truncatePseudo, updatePlayer }}>
            {props.children}
        </CurrentUserContext.Provider>
    );
}

export { CurrentUserContext, CurrentUserProvider };