import React, { useContext, useState } from 'react';

import CircularSlider from './CircularSlider';

import ROTATE_PLAYER_ANIM_WHITE from '../assets/images/picto-direction-vehicule-white.svg';
import ROTATE_PLAYER_ANIM_GREEN from '../assets/images/picto-direction-vehicule-green.svg';
import ROTATE_PLAYER_ANIM_ORANGE from '../assets/images/picto-direction-vehicule-orange.svg';
import ROTATE_PLAYER_ANIM_RED from '../assets/images/picto-direction-vehicule-red.svg';
import { CurrentUserContext } from '../_contexts/CurrentUserContext';

let tooltipTimeout;

const ROTATE_PLAYER_ANIM_COLORS = {
    white: ROTATE_PLAYER_ANIM_WHITE,
    green: ROTATE_PLAYER_ANIM_GREEN,
    orange: ROTATE_PLAYER_ANIM_ORANGE,
    red: ROTATE_PLAYER_ANIM_RED
};

function Snowmobile({ player, gray = false, setChangeDirection = null }) {
    const { currentUser } = useContext(CurrentUserContext);
    const [showTooltip, setShowTooltip] = useState(false);

    const style = {
        position: 'absolute',
        transform: `translate(-50%, -50%)`,
        left: `${player.position.x}px`,
        top: `${player.position.y}px`,
        filter: `grayscale(${gray ? 100 : 0}%)`,
        zIndex: `${gray ? 1 : 2}`
    };

    if (player.snowmobile === null) {
        return false;
    }

    const _toggleTooltip = () => {
        setShowTooltip((prevShowTooltip) => !prevShowTooltip);

        if (tooltipTimeout) {
            clearTimeout(tooltipTimeout);
        }

        tooltipTimeout = setTimeout(() => {
            setShowTooltip(false);
        }, 2000);
    };

    const imageName = `small-${player.snowmobile}.png`;
    const imagePath = require(`../assets/images/bateaux/${imageName}`);

    return (
        <div className={gray ? 'container-snowmobile' : 'container-snowmobile current-player'} style={style} onClick={_toggleTooltip} data-pseudo={player.pseudo}>
            <img src={imagePath} width={30} className='snowmobile' style={{ transform: `rotate(${player.direction}deg)` }} />

            {gray && showTooltip &&
                <div className='tooltip-pseudo'>
                    {player.pseudo}
                </div>
            }

            {gray === false &&
                <>
                    <div className='rotate-player img' style={{ transform: `translate(-50%, -50%) rotate(${player.direction}deg)` }}>
                        <img src={ROTATE_PLAYER_ANIM_COLORS[currentUser.colorPixel]} />
                    </div>

                    <div className='rotate-player'>
                        <CircularSlider setChangeDirection={setChangeDirection} />
                    </div>
                </>
            }
        </div>
    );
}

export default Snowmobile;
