import { Navigate, Outlet } from 'react-router-dom';
import React, { useContext } from 'react';
import moment from 'moment';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SUMMER_BREAK_END, SUMMER_BREAK_START } from './config';

function PrivateRoute() {
    const { isAuth } = useContext(CurrentUserContext);

    const hasVisited = localStorage.getItem('hasVisited');

    if (isAuth()) {
        const now = moment();
        const isSummerBreak = now.isBetween(moment(SUMMER_BREAK_START), moment(SUMMER_BREAK_END));

        if (isSummerBreak) {
            return <Navigate to='/pause' />;
        }
        else {
            return <Outlet />;
        }
    }
    else {
        if (hasVisited === '1') {
            return <Navigate to='/login' />;
        }
        else {
            return <Navigate to='/inscription/step-1' />;
        }
    }
}

export default PrivateRoute;
