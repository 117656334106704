import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import Layout from '../components/Layout';

import PICTO_LOGOUT from '../assets/images/picto-logout.svg';
import PICTO_BACK from '../assets/images/picto-back.svg';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';

function LegalNoticePage() {
    const { isAuth, logout } = useContext(CurrentUserContext);

    return (
        <Layout>
            <main className='legal-notice'>
                {isAuth() && (
                    <TopBar />
                )}

                <div className='popin'>
                    <div className='title'>
                        Mentions légales
                    </div>

                    <div className='content'>
                        <p>
                            Le Site est un site Internet de la société 3M France,<br />
                            3M France, Société par Actions Simplifiée au capital de 10.572.672 Enregistrée à Pontoise sous le n° RCS 542 078 555<br />
                            Domiciliée 1 parvis de l’Innovation, 95000 Cergy<br />
                            TVA intracommunautaire : FR 255 420 78 555
                        </p>

                        <p>
                            Directeur de publication : Piranha Communication<br />
                            Développement : Hop Créative Services<br />
                            Hébergement du site : OVH 2 rue Kellermann 59100 ROUBAIX France<br />
                            Pour contacter cet hébergeur, rendez-vous à l’adresse www.ovh.com/fr/support
                        </p>
                    </div>
                </div>

                <div className='menu'>
                    <Link to='/'>
                        <img src={PICTO_BACK} width={34} />

                        <span>Retour à la course</span>
                    </Link>

                    {isAuth() && (
                        <button onClick={logout}>
                            <img src={PICTO_LOGOUT} width={25} />

                            <span>Déconnexion</span>
                        </button>
                    )}
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default LegalNoticePage;
