import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer'>
            <Link to='/cgu'>
                <span>CGU</span>
            </Link>

            <span>{` - `}</span>

            <Link to='/mentions-legales'>
                <span>Mentions légales</span>
            </Link>

            <span>{` - `}</span>

            <a href='mailto:assistance@inc-regatta.fr'>
                <span>Contact</span>
            </a>

            <br />

            <a href='https://www.hop-creativeservices.fr/' target='_blank'>
                <span>Imaginé par l’agence Hop Créative Services</span>
            </a>
        </div>
    );
}

export default Footer;
