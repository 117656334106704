import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

import PICTO_EYE from '../assets/images/picto-eye.svg';
import LOGO from '../assets/images/logo-incentive-lyreco.svg';

function ResetPasswordPage() {
    const { token } = useParams();
    const { currentUser, login } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [canEdit, setCanEdit] = useState(false);
    const [uuid, setUuid] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordBis, setPasswordBis] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        socket.emit('client:server:verify-reset-token', { token });

        socket.on('server:client:verify-reset-token', (data) => {
            if (data.error) {
                setErrorMessage(data.error);
            }
            else {
                setUuid(data.uuid);
                setCanEdit(true);
            }
        });

        socket.on('server:client:update-password', (data) => {
            if (data.error) {
                setErrorMessage(data.error);
            }
            else {
                setRedirect(true);
            }
        });

        return () => {
            socket.off('server:client:verify-reset-token');
            socket.off('server:client:update-password');
        };
    }, [login, socket, token]);

    const _submit = () => {
        setErrorMessage('');

        if (password === '') {
            setErrorMessage('Veuillez renseigner votre mot de passe');
        }
        else if (password !== passwordBis) {
            setErrorMessage('Les mots de passe ne correspondent pas');
        }
        else {
            const user = { uuid, password };

            socket.emit('client:server:update-password', user);
        }
    };

    const _togglePassword = () => {
        setPasswordFieldType(passwordFieldType === 'text' ? 'password' : 'text');
    };

    if (redirect || (currentUser && currentUser.snowmobile)) {
        return <Navigate to='/login' />;
    }

    if (!uuid && !errorMessage) {
        return (
            <Layout>
                <main className='reset-password'>
                    <div className='popin'>
                        <div className='title'>Vérification du token...</div>
                    </div>
                </main>
            </Layout>
        );
    }

    return (
        <Layout>
            <main className='login'>
                <img src={LOGO} width={183} className='img-logo' alt='Logo Incentive Lyreco' />

                <div className='popin'>
                    <div className='title'>Réinitialiser votre mot de passe</div>

                    {canEdit &&
                        <>
                            <div className='password-container'>
                                <input
                                    type={passwordFieldType}
                                    className='password'
                                    placeholder='Nouveau mot de passe'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete='off'
                                />

                                <img src={PICTO_EYE} width={17} onClick={_togglePassword} />
                            </div>

                            <div className='password-container'>
                                <input
                                    type={passwordFieldType}
                                    className='password-bis'
                                    placeholder='Confirmer le mot de passe'
                                    value={passwordBis}
                                    onChange={(e) => setPasswordBis(e.target.value)}
                                    autoComplete='off'
                                />

                                <img src={PICTO_EYE} width={17} onClick={_togglePassword} />
                            </div>
                        </>
                    }

                    {errorMessage && <div className='txt-error text-danger'>{errorMessage}</div>}

                    <button onClick={_submit}>Réinitialiser le mot de passe</button>
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default ResetPasswordPage;
