import { CircleSlider } from 'react-circle-slider';
import React, { useContext } from 'react';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';

let timeout = null;

const CircularSlider = ({ setChangeDirection }) => {
    const { currentUser, updatePlayer } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const handleSliderChange = (newValue) => {
        setChangeDirection(true);

        if (timeout !== null) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            socket.emit('client:server:change-direction', { uuid: currentUser.uuid, direction: currentUser.direction, currentStep: currentUser.currentStep });
        }, 500);

        updatePlayer({ direction: newValue });
    };

    return (
        <div>
            <CircleSlider
                value={currentUser.direction}
                onChange={handleSliderChange}
                size={120}
                circleWidth={0}
                progressWidth={0}
                knobRadius={0}
                min={0}
                max={359}
                stepSize={1}
                circleColor='white'
                progressColor='white'
                knobColor='white'
                gradientColorFrom='white'
                gradientColorTo='white'
                shadow={false}
                showTooltip={false}
                showPercentage={false}
            />
        </div>
    );
};

export default CircularSlider;
