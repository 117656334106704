const env = process.env.REACT_APP_ENV || 'development';

let socketioUrl = 'localhost:3001';
let serverUrl = 'http://localhost:3001';
let summerBreakStart = '2024-05-20 00:00:00';
let summerBreakEnd = '2024-09-01 23:59:59';

if (env === 'production') {
    socketioUrl = 'wss://server.inc-regatta.fr';
    serverUrl = 'https://server.inc-regatta.fr';
    summerBreakStart = '2024-07-20 00:00:00';
    summerBreakEnd = '2024-09-01 23:59:59';
}

export const SOCKETIO_URL = socketioUrl;
export const SERVER_URL = serverUrl;
export const SUMMER_BREAK_START = summerBreakStart;
export const SUMMER_BREAK_END = summerBreakEnd;
