import React, { useState, useEffect, useContext } from 'react';

import { SocketContext } from '../_contexts/SocketContext';
// import SocketStatus from './SocketStatus';

function Layout({ children }) {
    const { socket } = useContext(SocketContext);

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        socket.on('server:message', (message) => {
            console.log(message);
            setMessage(message);
        });

        socket.on('server:error', (error) => {
            console.log(error);
            setError(error);
        });

        return () => {
            socket.off('server:message');
            socket.off('server:error');
        };
    }, []);

    return (
        <>
            {message !== '' && (
                <div className='alert alert-info alert-dismissible py-1 m-0 fixed-top rounded-0' role='alert'>
                    {message}

                    <button type='button' className='btn-close py-2' aria-label='Close' onClick={() => setMessage('')}></button>
                </div>
            )}

            {children}

            {error !== '' && (
                <div className='alert alert-danger alert-dismissible py-1 m-0 fixed-bottom rounded-0' role='alert'>
                    {error}

                    <button type='button' className='btn-close py-2' aria-label='Close' onClick={() => setError('')}></button>
                </div>
            )}

            {/*<SocketStatus />*/}
        </>
    );
}

export default Layout;
