import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

import LOGO from '../assets/images/logo-incentive-lyreco.svg';
import LOGO_LYRECO from '../assets/images/logo-lyreco.svg';
import LOGO_3M from '../assets/images/logo-3m.svg';
import PICTO_EYE from '../assets/images/picto-eye.svg';

function LoginPage() {
    const { isAuth, login } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [pseudo, setPseudo] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordFieldType, setPasswordFieldType] = useState('password');

    useEffect(() => {
        socket.on('server:client:login', (data) => {
            if (data.error) {
                setErrorMessage(data.error);

                localStorage.removeItem('pseudo');
                localStorage.removeItem('password');
            }
            else {
                login(data, (error) => {
                    setErrorMessage(error);
                });
            }
        });

        const storedPseudo = localStorage.getItem('pseudo');
        const storedPassword = localStorage.getItem('password');

        if (storedPseudo && storedPassword) {
            // TODO CHANGE VERSION
            const user = { pseudo: storedPseudo, password: storedPassword, v: 1 };

            socket.emit('client:server:login', user);
        }

        return () => {
            socket.off('server:client:login');
        };
    }, []);

    const _submit = () => {
        setErrorMessage('');

        // TODO CHANGE VERSION
        const user = { pseudo, password, v: 1 };

        socket.emit('client:server:login', user);

        localStorage.setItem('pseudo', pseudo);
        localStorage.setItem('password', password);
        localStorage.setItem('hasVisited', '1');
    };

    const _togglePassword = () => {
        if (passwordFieldType === 'text') {
            setPasswordFieldType('password');
        }
        else {
            setPasswordFieldType('text');
        }
    };

    if (isAuth()) {
        return <Navigate to='/' />;
    }
    else {
        return (
            <Layout>
                <main className='login'>
                    <img src={LOGO} width={183} className='img-logo' />

                    <div className='popin'>
                        <div className='title'>Connexion</div>

                        <input type='text' className='login' placeholder='Identifiant' value={pseudo} onChange={(e) => setPseudo(e.target.value)} autoComplete='off' />

                        <div className='password-container'>
                            <input type={passwordFieldType} className='password' placeholder='Mot de passe' value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='off' />

                            <img src={PICTO_EYE} width={17} onClick={_togglePassword} />
                        </div>

                        {errorMessage !== '' && <div className='desc-password text-danger'>{errorMessage}</div>}

                        <button onClick={_submit}>Se connecter</button>

                        <Link to='/inscription/step-1'>Première connexion</Link>

                        <Link to='/password-request'>Mot de passe oublié ?</Link>
                    </div>

                    <div className='logos'>
                        <img src={LOGO_LYRECO} width={103} />

                        <img src={LOGO_3M} width={61} />
                    </div>

                    <Footer />
                </main>
            </Layout>
        );
    }
}

export default LoginPage;
