import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import StepRankingTable from '../components/StepRankingTable';
import Layout from '../components/Layout';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';

import PICTO_LOGOUT from '../assets/images/picto-logout.svg';
import PICTO_BACK from '../assets/images/picto-back.svg';

function RankingPage() {
    const { currentUser, logout } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [players, setPlayers] = useState([]);

    useEffect(() => {
        socket.on('server:client:get-players-rank', (data) => {
            if (data.error) {
                console.log(data.error);
            }
            else {
                setPlayers(data);
            }
        });
        socket.emit('client:server:get-players-rank');

        return () => {
            socket.off('server:client:get-players-rank');
        };
    }, []);

    return (
        <Layout>
            <main className='ranking'>
                <div>
                    <TopBar />

                    {currentUser.currentStep === 1 && players.length > 0 && <StepRankingTable dataPlayers={players} stepTable={1} />}
                    {currentUser.currentStep === 2 && players.length > 0 && <StepRankingTable dataPlayers={players} stepTable={2} />}
                </div>

                <div className='menu'>
                    {currentUser.inSession !== false && <Link to='/'>
                        <img src={PICTO_BACK} width={34} />

                        <span>Retour à la course</span>
                    </Link>}

                    <button onClick={logout}>
                        <img src={PICTO_LOGOUT} width={25} />

                        <span>Déconnexion</span>
                    </button>
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default RankingPage;
