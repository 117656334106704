import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import Layout from '../components/Layout';

import PICTO_LOGOUT from '../assets/images/picto-logout.svg';
import PICTO_TUTO_1 from '../assets/images/tuto-1.png';
import PICTO_TUTO_2 from '../assets/images/tuto-2.png';
import PICTO_TUTO_3 from '../assets/images/tuto-3.png';
import PICTO_TUTO_4 from '../assets/images/tuto-4.png';
import PICTO_BACK from '../assets/images/picto-back.svg';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';

function InstructionsPage() {
    const { logout } = useContext(CurrentUserContext);

    return (
        <Layout>
            <main className='instructions'>
                <TopBar />

                <div className='popin'>
                    <div className='title'>
                        Instruction de participation
                    </div>

                    <div className='content'>
                        <p>
                            L’objectif du jeu est de suivre avec son bateau une trajectoire représenté par une ligne rouge sur la carte de navigation. En restant sur la ligne vous atteindrez la vitesse maximal de 30 nœuds. En vous éloignant de la ligne rouge, le bateau perd progressivement de la vitesse. Trop loin de la ligne rouge, le bateau fini par ne plus avancer du tout. Vous devrez donc faire appel à la fonction Dépannage qui interviendra dans un délai de 6h.
                            <br />
                        </p>

                        <p>
                            À l’issu de chaque étape, un classement permet d’attribuer des points de performance au 50 premiers.
                            <br />
                            Les points de performances s’additionneront à des points business qui seront déterminés par votre direction commerciale et communiquer quelques jours après la fin de l’étape.
                        </p>

                        <p>
                            Tout au long d’une étape, vous pourrez gagner des bonus appelé booster. Un booster permet d’augmenter de 25% la vitesses de votre bateau durant une période de 24h et ainsi atteindre la vitesse record de 37,5 nœuds.
                            <br />
                            Vous pourrez également obtenir un booster en regardant les médias autour des produits Scotch® et Post-it®. Les médias sont soit un fichier PDF, Powerpoint ou soit un fichier vidéo. À l’issu de chaque média lu, le participant gagne un booster. Certains média arriveront durant l’étape. Pensez à les consulter régulièrement.
                        </p>

                        <p>
                            En cas de besoin, une assistance est à disposition afin de contacter l’équipe technique.
                        </p>

                        <hr />

                        <p>
                            <strong>LES FONCTIONS EN DÉTAIL</strong>
                        </p>

                        <p>
                            <strong>BOOSTER</strong> - Un booster permet d’augmenter de 25% sa vitesse pendant 24h. On obtient un booster en répondant correctement à la question du jour ou en consultant un média.
                        </p>

                        <p>
                            <strong>CLASSEMENT</strong> - Permet de voir le classement de l’étape en cours. Seul les 50 premiers marqueront des points.
                        </p>

                        <p>
                            <strong>POINT BUSINESS</strong> - En atteignant votre objectif commercial, vous obtiendrez des points business qui s’additionneront à vos points performance.
                        </p>

                        <p>
                            <strong>ASSISTANCE</strong> - Permet de contacter l’équipe technique en cas de bug ou de blocage de connexion par exemple
                        </p>

                        <p>
                            <strong>DÉPANNAGE</strong> - Votre bateau n’avance plus ? Vous êtes échoué ? Utilisez l’assistance pour revenir sur la ratjectoire. Une pénalité de 6h vous sera appliqué.
                        </p>

                        <p>
                            <strong>AUTO-PILOTE</strong> - En cas d’absence, vous pouvez utiliser la fonction auto-pilote afin que votre bateau reste sur une bonne trajectoire. Attention, votre vitesse sera limitée à 20 nœuds. Vous devez utiliser cette fonction avec modération.
                        </p>
                    </div>
                </div>

                <div className='menu'>
                    <Link to='/'>
                        <img src={PICTO_BACK} width={34} />

                        <span>Retour à la course</span>
                    </Link>

                    <button onClick={logout}>
                        <img src={PICTO_LOGOUT} width={25} />

                        <span>Déconnexion</span>
                    </button>
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default InstructionsPage;
