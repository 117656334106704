import React, { useContext, useEffect, useRef, useState } from 'react';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import Snowmobile from './Snowmobile';
import isIOS from '../utils/IsIOS';

let mapElement;
let mapContainerElement;
let minZoom;
let maxZoom;
let mapElementScale;
let lastPos;
let delta;
let mapRect;
let initialLeft;
let initialTop;
let initialTransform;

function Map({ hasQuiz }) {
    const { currentUser, updatePlayer } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [players, setPlayers] = useState([]);
    const [imageMapSize, setImageMapSize] = useState(null);
    const [changeDirection, setChangeDirection] = useState(false);

    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);

    const imageName = `map-${currentUser.currentStep}.jpg`;
    const MAP_IMAGE = require(`../assets/images/maps/${imageName}`);

    useEffect(() => {
        socket.on('server:client:get-players-position', (data) => {
            setPlayers(data.filter((p) => p.uuid !== currentUser.uuid));
        });

        socket.on('server:client:get-my-position', (data) => {
            updatePlayer(data);
        });

        if (hasQuiz) {
            return () => {
                socket.off('server:client:get-players-position');
                socket.off('server:client:get-my-position');
            };
        }
        else {
            const interval1 = setInterval(() => {
                socket.emit('client:server:get-players-position');
            }, 12000); // Mettre à jour toutes les 10 secondes

            const interval2 = setInterval(() => {
                socket.emit('client:server:get-my-position', currentUser.uuid);
            }, 12000); // Mettre à jour toutes les 10 secondes

            return () => {
                clearInterval(interval1);
                clearInterval(interval2);

                socket.off('server:client:get-players-position');
                socket.off('server:client:get-my-position');
            };
        }
    }, [hasQuiz, updatePlayer]);

    useEffect(() => {
        socket.emit('client:server:get-players-position');
        socket.emit('client:server:get-my-position', currentUser.uuid);

        const image = new Image();
        image.src = MAP_IMAGE;

        image.onload = () => {
            setImageMapSize({ width: image.width, height: image.height });
        };
    }, []);

    useEffect(() => {
        if (imageMapSize === null) {
            return;
        }

        mapElement = mapRef.current;
        mapContainerElement = mapContainerRef.current;
        minZoom = 1;
        maxZoom = 3;
        mapElementScale = minZoom;
        lastPos = {};
        delta = { x: 0, y: 0 };

        // Ajoutez ce code pour centrer la carte initialement
        mapRect = mapElement.getBoundingClientRect();
        initialLeft = (mapContainerElement.offsetWidth - mapRect.width * mapElementScale) / 2 - currentUser.position.x * mapElementScale + (mapRect.width * mapElementScale - mapContainerElement.offsetWidth) / 2;
        initialTop = (mapContainerElement.offsetHeight - mapRect.height * mapElementScale) / 2 - currentUser.position.y * mapElementScale + (mapRect.height * mapElementScale - mapContainerElement.offsetHeight) / 2;
        initialTransform = `translate3d(${initialLeft}px, ${initialTop}px, 0) scale(${mapElementScale})`;

        delta = { x: initialLeft, y: initialTop };
        mapElement.style.transform = initialTransform;
        mapElement.style.WebkitTransform = initialTransform;

        _checkBoundaries();
    }, [imageMapSize]);

    useEffect(() => {
        if (imageMapSize === null || changeDirection === true) {
            return;
        }

        const _distance = (event) => {
            return Math.hypot(
                event.touches[0].pageX - event.touches[1].pageX,
                event.touches[0].pageY - event.touches[1].pageY
            );
        };

        const _handleTouchStart = (event) => {
            if (!event.target.classList.contains('snowmobile') && !event.target.classList.contains('tooltip-pseudo')) {
                if (event.touches.length === 1) {
                    event.preventDefault();

                    lastPos.x = event.touches[0].pageX;
                    lastPos.y = event.touches[0].pageY;
                }
                else if (event.touches.length === 2) {
                    event.preventDefault();

                    lastPos.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                    lastPos.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                    lastPos.distance = _distance(event);
                }
            }
        };

        const _handleTouchMove = (event) => {
            if (!event.target.classList.contains('snowmobile') && !event.target.classList.contains('tooltip-pseudo')) {
                if (event.touches.length === 1) {
                    event.preventDefault();

                    delta.x += event.touches[0].pageX - lastPos.x;
                    delta.y += event.touches[0].pageY - lastPos.y;

                    lastPos.x = event.touches[0].pageX;
                    lastPos.y = event.touches[0].pageY;

                    const transform = `translate3d(${delta.x}px, ${delta.y}px, 0) scale(${mapElementScale})`;

                    mapElement.style.transform = transform;
                    mapElement.style.WebkitTransform = transform;

                    _checkBoundaries();
                }
                else if (event.touches.length === 2) {
                    event.preventDefault();

                    lastPos.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                    lastPos.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;

                    let scale;
                    if (event.scale) {
                        scale = event.scale;
                    }
                    else {
                        const deltaDistance = _distance(event);

                        scale = deltaDistance / lastPos.distance;
                    }

                    const speedZoom = isIOS() ? 0.2 : 1;

                    mapElementScale += (scale - 1) * speedZoom;
                    mapElementScale = Math.min(Math.max(minZoom, mapElementScale), maxZoom);

                    lastPos.distance = _distance(event);

                    const transform = `translate3d(${delta.x}px, ${delta.y}px, 0) scale(${mapElementScale})`;

                    mapElement.style.transform = transform;
                    mapElement.style.WebkitTransform = transform;

                    _checkBoundaries();
                }
            }
        };

        const _handleMouseDown = (event) => {
            if (!event.target.classList.contains('snowmobile') && !event.target.classList.contains('tooltip-pseudo')) {
                if (event.button === 0) {
                    event.preventDefault();

                    lastPos.x = event.clientX;
                    lastPos.y = event.clientY;

                    document.addEventListener('mousemove', _handleMouseMove);
                    document.addEventListener('mouseup', _handleMouseUp);
                }
            }
        };

        const _handleMouseMove = (event) => {
            if (!event.target.classList.contains('snowmobile') && !event.target.classList.contains('tooltip-pseudo')) {
                event.preventDefault();

                delta.x += event.clientX - lastPos.x;
                delta.y += event.clientY - lastPos.y;

                lastPos.x = event.clientX;
                lastPos.y = event.clientY;

                const transform = `translate3d(${delta.x}px, ${delta.y}px, 0) scale(${mapElementScale})`;

                mapElement.style.transform = transform;
                mapElement.style.WebkitTransform = transform;

                _checkBoundaries();
            }
        };

        const _handleMouseUp = (event) => {
            if (event.button === 0) {
                event.preventDefault();

                document.removeEventListener('mousemove', _handleMouseMove);
                document.removeEventListener('mouseup', _handleMouseUp);
            }
        };

        const _handleWheel = (event) => {
            event.preventDefault();

            const speedZoom = 0.1;
            const scaleDelta = event.deltaY > 0 ? -speedZoom : speedZoom;

            mapElementScale += scaleDelta;
            mapElementScale = Math.min(Math.max(minZoom, mapElementScale), maxZoom);

            const transform = `translate3d(${delta.x}px, ${delta.y}px, 0) scale(${mapElementScale})`;

            mapElement.style.transform = transform;
            mapElement.style.WebkitTransform = transform;

            _checkBoundaries();
        };

        mapElement.addEventListener('touchstart', _handleTouchStart);
        mapElement.addEventListener('touchmove', _handleTouchMove);
        mapElement.addEventListener('mousedown', _handleMouseDown);
        mapElement.addEventListener('wheel', _handleWheel);

        return () => {
            mapElement.removeEventListener('touchstart', _handleTouchStart);
            mapElement.removeEventListener('touchmove', _handleTouchMove);
            mapElement.removeEventListener('mousedown', _handleMouseDown);
            mapElement.removeEventListener('wheel', _handleWheel);
        };
    }, [imageMapSize, changeDirection]);

    useEffect(() => {
        if (changeDirection === false) {
            return;
        }

        const _handleMouseUp = (event) => {
            event.preventDefault();

            setChangeDirection(false);
        };

        const _handleTouchEnd = (event) => {
            event.preventDefault();

            setChangeDirection(false);
        };

        mapElement.addEventListener('mousedown', _handleMouseUp);
        mapElement.addEventListener('touchend', _handleTouchEnd);

        return () => {
            mapElement.removeEventListener('mousedown', _handleMouseUp);
            mapElement.removeEventListener('touchend', _handleTouchEnd);
        };
    }, [changeDirection]);

    const _checkBoundaries = () => {
        const mapRect = mapElement.getBoundingClientRect();
        const containerRect = mapContainerElement.getBoundingClientRect();

        const minX = -containerRect.width / 2;
        const maxX = (-mapRect.width + (containerRect.width / 2)); // 4320
        const minY = -containerRect.height / 2;
        const maxY = (-mapRect.height + (containerRect.height / 2));

        delta.x = Math.min(minX, Math.max(maxX, delta.x));
        delta.y = Math.min(minY, Math.max(maxY, delta.y));

        mapElementScale = 1;

        const transform = `translate3d(${delta.x}px, ${delta.y}px, 0) scale(${mapElementScale})`;

        mapElement.style.transform = transform;
        mapElement.style.WebkitTransform = transform;
    };

    return (
        <div className='map-container' ref={mapContainerRef}>
            <div className='map' ref={mapRef}>
                <img src={MAP_IMAGE} alt='Carte du jeu' />

                <div className='players'>
                    <Snowmobile player={currentUser} setChangeDirection={setChangeDirection} />

                    {players.map((player, index) => (
                        <Snowmobile key={index} player={player} gray={true} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Map;
