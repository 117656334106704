import React from 'react';

function BigSnowmobile({ id, color = 'bleu' }) {
    const imageName = `big-${id}-${color === null ? 'bleu' : color}.jpg`;
    const imagePath = require(`../assets/images/bateaux/${imageName}`);

    return <img src={imagePath} width={150} />;
}

export default BigSnowmobile;
