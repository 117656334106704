import React from 'react';

import { CurrentUserProvider } from './CurrentUserContext';
import { SocketProvider } from './SocketContext';

function AllContext({ children }) {
    return (
        <SocketProvider>
            <CurrentUserProvider>
                {children}
            </CurrentUserProvider>
        </SocketProvider>
    );
}

export default AllContext;