import ReactDOM from 'react-dom/client';
import React from 'react';

import Layout from './components/Layout';
import AllContext from './_contexts';
import AllRoutes from './_routes/';

import './assets/scss/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AllContext>
        <Layout>
            <AllRoutes />
        </Layout>
    </AllContext>
);
