import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import Layout from '../components/Layout';

import PICTO_LOGOUT from '../assets/images/picto-logout.svg';
import PICTO_BACK from '../assets/images/picto-back.svg';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';

function CguPage() {
    const { isAuth, logout } = useContext(CurrentUserContext);

    return (
        <Layout>
            <main className='legal-notice'>
                {isAuth() && (
                    <TopBar />
                )}

                <div className='popin'>
                    <div className='title'>
                        Conditions Générales d'Utilisation (CGU) pour l'Incentive Commerciale BRUNEAU
                    </div>

                    <div className='content'>
                        <ol>
                            <li>
                                <strong>Organisation et Participants</strong><br />
                                Cette incentive commerciale, nommée « REGATTA CHALLENGE BRUNEAU, est exclusivement réservée aux commerciaux salariés de la société BRUNEAU. Elle est organisée par le groupe 3M France, Société par Actions Simplifiée, au capital de 10 572 672 €, dont le siège social est situé 1 parvis de l’Innovation, 95000 Cergy, immatriculée au RCS de Pontoise sous le numéro 542 078 555 (ci-après dénommée l’ « Organisateur ») en collaboration avec l'agence de communication PiRANHA.<br />
                                <br />
                            </li>

                            <li>
                                <strong>Inscription et Déroulement</strong><br />
                                Pour participer, les commerciaux de BRUNEAU doivent s'inscrire en fournissant leur nom, prénom, e-mail professionnel et choisir un mot de passe. Ensuite, chaque participant sélectionne un type de bateau parmi les 4 proposés et choisit un design pour terminer l'inscription. Le jeu se déroule en deux étapes :<br />
                                <ul>
                                    <li>La Régate d’Écosse : du 3 juin au 19 juillet.</li>
                                    <li>La Route des Couleurs : du 2 septembre au 31 octobre.</li>
                                </ul>
                                <br />
                            </li>

                            <li>
                                <strong>Objectif et Mécanique du Jeu</strong><br />
                                L'objectif est de participer à une régate digitale en suivant une trajectoire représentée par une ligne rouge sur la carte de navigation. En s'éloignant de cette ligne, le bateau perd progressivement de la vitesse. En cas d'éloignement excessif, une pénalité de 6 heures est appliquée. À la fin de chaque étape, un classement est établi pour attribuer des points de performance. Ces points s'ajoutent à des points business déterminés par la direction des ventes de BRUNEAU.<br />
                                <br />
                            </li>

                            <li>
                                <strong>Bonus et Récompenses</strong><br />
                                Les participants peuvent gagner des boosters tout au long de l'étape, augmentant la vitesse de leur bateau de 25% pendant 24 heures. Les boosters sont obtenus en regardant des médias du groupe 3M et en répondant correctement à des questions tout au long de l’animation commerciale (PDF, Powerpoint ou vidéo), Les premiers de chaque course remportent :<br />
                                <ul>
                                    <li><strong>LE 1ER RECEVRA</strong> : une carte cadeau d’un montant de 190€</li>
                                    <li><strong>LE 2E RECEVRA</strong> : une carte cadeau d’un montant de 150€</li>
                                    <li><strong>LE 3E RECEVRA</strong> : une carte cadeau d’un montant de 125€</li>
                                    <li><strong>LE 4E RECEVRA</strong> : une carte cadeau d’un montant de 100€</li>
                                    <li><strong>LES 5E ET 6E</strong> : une carte cadeau d’un montant de 80€</li>
                                </ul>
                                <br />
                            </li>

                            <li>
                                <strong>Assistance Technique</strong><br />
                                En cas de besoin, une assistance technique est disponible via un formulaire en ligne ou par téléphone au 07 76 57 71 90.<br />
                                <br />
                            </li>

                            <li>
                                <strong>Protection des Données Personnelles</strong><br />
                                <strong>6.1. Collecte et Traitement des Données</strong><br />
                                Les données personnelles des participants collectées dans le cadre de cette incentive commerciale sont nécessaires pour la gestion du jeu et sont traitées conformément aux dispositions légales en vigueur et aux présentes CGU. Les participants consentent à la collecte et au traitement de leurs données personnelles dans le cadre de leur participation au jeu.<br />
                                <br />
                                <strong>6.2. Utilisation des Données</strong><br />
                                Les données collectées sont utilisées dans le but de gérer l'inscription et la participation des participants au jeu, d'attribuer les récompenses et de communiquer avec les participants concernant le jeu. Elles ne seront en aucun cas cédées à des tiers ou utilisées à des fins commerciales sans le consentement explicite des participants.<br />
                                <br />
                                <strong>6.3. Droits des Participants</strong><br />
                                Conformément à la législation en vigueur sur la protection des données personnelles, les participants disposent d'un droit d'accès, de rectification, de suppression et d'opposition aux données les concernant. Ils peuvent exercer ces droits en contactant l'organisateur du jeu aux coordonnées fournies dans les présentes CGU.<br />
                                <br />
                                <strong>6.4. Conservation des Données</strong><br />
                                Les données personnelles des participants seront conservées uniquement pour la durée nécessaire à la réalisation des finalités mentionnées ci-dessus ou pour se conformer aux obligations légales et réglementaires.<br />
                                Conformément à la législation en vigueur en matière de données personnelles, les Participants disposent d’un droit d’accès, de rectification des données les concernant. Les Participants peuvent également demander la minimisation ou la suppression des données personnelles les concernant dans le respect des obligations légales et des finalités du traitement. Toute demande peut être adressée à : DPO_EU@mmm.com<br />
                                <br />
                                <strong>6.5. Responsabilité de l'Organisateur</strong><br />
                                L'organisateur s'engage à prendre toutes les mesures nécessaires pour assurer la sécurité et la confidentialité des données personnelles des participants et à les traiter de manière confidentielle. Il ne saurait être tenu responsable des incidents liés à la sécurité des données qui échappent à son contrôle, notamment en cas d'accès non autorisé par des tiers.<br />
                                <br />
                            </li>

                            <li>
                                <strong>Responsabilité de l'Organisateur</strong><br />
                                L’Organisateur se réserve le droit d'écourter, de prolonger, de suspendre ou d'annuler le Jeu en cas de force majeure ou d'événement indépendant de sa volonté. Il ne saurait être tenu pour responsable des incidents techniques ou de toute autre difficulté rencontrée par les Participants.<br />
                                <br />
                            </li>

                            <li>
                                <strong>Le règlement est exclusivement régi par la loi française.</strong><br />
                                Toute question relative au Règlement et notamment à sa validité, à son application ou à son interprétation, devra être transmise à l’Organisateur. A défaut de résolution amiable du conflit, les tribunaux compétents pourront être saisis.<br />
                                <br />
                            </li>
                        </ol>

                        <p>
                            Ces CGU sont soumises à l'acceptation des participants et sont contraignantes pour tous les inscrits. En cas de non-respect des règles énoncées, des mesures appropriées pourront être prises, y compris la disqualification du participant.
                        </p>
                    </div>
                </div>

                <div className='menu'>
                    <Link to='/'>
                        <img src={PICTO_BACK} width={34} />

                        <span>Retour à la course</span>
                    </Link>

                    {isAuth() && (
                        <button onClick={logout}>
                            <img src={PICTO_LOGOUT} width={25} />

                            <span>Déconnexion</span>
                        </button>
                    )}
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default CguPage;
