import React, { useContext, useEffect, useState } from 'react';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';

import PICTO_RANKING from '../assets/images/picto-classement.svg';

function StepRankingTable({ dataPlayers, stepTable }) {
    const { currentUser } = useContext(CurrentUserContext);

    const [players, setPlayers] = useState([]);
    const [player, setPlayer] = useState({});
    const [isRanked, setIsRanked] = useState(false);
    const [myRank, setMyRank] = useState(null);

    const [step] = useState(stepTable > 0 ? stepTable : 1);
    const [stepName, setStepName] = useState('');
    const [tempRank, setTempRank] = useState(false);

    useEffect(() => {
        const isTemp = step >= currentUser.currentStep;
        setTempRank(isTemp);

        let arrayPlayers = [];

        dataPlayers.forEach((p, index) => {
            const player = {
                'uuid': p.uuid,
                'pseudo': p.pseudo,
                'type': p.type,
                'type2': p.type2,
                'isStarted': p[`step${step}IsStarted`],
                'cumulKM': p[`step${step}CumulKM`],
                'stepPoints': p[`step${step}Points`],
                'businessPoints': p[`step${step}BusinessPoints`],
                'rankStep': p[`rankStep${step}`]
            };

            if (p[`nearestIndex${step}`] > 0) {
                arrayPlayers.push(player);
            }

            if (p.uuid === currentUser.uuid) {
                setPlayer(player);
                setIsRanked(index < 12);
                setMyRank(index);
            }
        });

        if (isTemp) {
            arrayPlayers.sort((a, b) => a.rankStep - b.rankStep);
        }
        else {
            arrayPlayers.sort((a, b) => (b.stepPoints + b.businessPoints) - (a.stepPoints + a.businessPoints));
        }

        setPlayers(arrayPlayers);

        switch (stepTable) {
            case 1:
                setStepName('La Régate d’Écosse');
                break;

            case 2:
                setStepName('La Route des Couleurs');
                break;
        }

    }, []);

    useEffect(() => {
        if (players && player) {
            const index = players.findIndex(p => p.uuid === player.uuid);

            setIsRanked(index + 1 <= 12);
            setMyRank(index + 1);
        }

    }, [players]);

    const _formatRank = (rank) => {
        const digits = players.length.toString().length;

        return rank.toString().padStart(digits, '0');
    };

    return (
        <table>
            <thead>
                {/*<tr>*/}
                {/*    <th colSpan={4}>*/}
                {/*        {stepName}*/}
                {/*    </th>*/}
                {/*</tr>*/}

                <tr>
                    <th>
                        <img src={PICTO_RANKING} width={18} style={{ marginRight: 3 }} />
                        <span>Joueurs</span>
                    </th>
                    <th>POINTS PERFORMANCE</th>
                    <th>Points Business</th>
                    <th>Total</th>
                </tr>
            </thead>

            <tbody className={isRanked ? 'large' : ''}>
                {myRank !== null && players.map((player, index) => {
                    return (
                        <tr key={index} className={currentUser.uuid === player.uuid ? 'player' : ''}>
                            {player.isStarted ?
                                <td>{_formatRank(index + 1)}&nbsp;&nbsp;&nbsp;{player.pseudo}</td>
                                :
                                <td>-</td>
                            }
                            <td>{player.stepPoints > 0 ? player.stepPoints + ' pts' : '-'}</td>
                            <td>{player.businessPoints > 0 ? player.businessPoints + ' pts' : '-'}</td>
                            <td>{(player.stepPoints + player.businessPoints) > 0 ? player.stepPoints + player.businessPoints + ' pts' : '-'}</td>
                        </tr>
                    );
                })}
            </tbody>

            {myRank !== null && !isRanked && (
                <tfoot>
                    <tr className='hidden'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>{_formatRank(myRank)}&nbsp;&nbsp;&nbsp;{player.pseudo}</td>
                        <td>{player.stepPoints} pts</td>
                        <td>{player.businessPoints} pts</td>
                        <td>{player.stepPoints + player.businessPoints} pts</td>
                    </tr>
                </tfoot>
            )}
        </table>
    );
}

export default StepRankingTable;
