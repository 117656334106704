import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { SocketContext } from '../_contexts/SocketContext';
import Layout from '../components/Layout';
import LOGO from '../assets/images/logo-incentive-lyreco.svg';
import { CurrentUserContext } from '../_contexts/CurrentUserContext';

function ValidateAccountPage() {
    const navigate = useNavigate();
    const { token } = useParams();
    const { socket } = useContext(SocketContext);
    const { isAuth, currentUser, login, logout } = useContext(CurrentUserContext);
    const [validationMessage, setValidationMessage] = useState('Veuillez valider votre compte avec le mail que vous avez reçu.');

    useEffect(() => {
        if (token) {
            socket.emit('client:server:validate-account', { token });

            socket.on('server:client:validate-account', (data) => {
                if (data.error) {
                    setValidationMessage('La validation du compte a échoué. Veuillez vérifier le lien dans votre email.');
                }
                else {
                    login({ ...data, isActive: true, gameTime: { duration: 600 } });
                    navigate('/');
                }
            });

            return () => {
                socket.off('server:client:validate-account');
            };
        }
    }, [token, socket]);

    const _logout = (e) => {
        e.preventDefault();

        logout();
        navigate('/');
    };

    if (isAuth() && currentUser.isActive) {
        return <Navigate to='/inscription/step-3' />;
    }

    return (
        <Layout>
            <main className='login'>
                <img src={LOGO} width={183} className='img-logo' />

                <div className='popin'>
                    <div className='title'>Validation de Compte</div>

                    <p>{validationMessage}</p>

                    {!token && <a href='#' onClick={_logout}>Se déconnecter</a>}
                </div>
            </main>
        </Layout>
    );
}

export default ValidateAccountPage;
