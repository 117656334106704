import React, { useContext, useEffect, useState } from 'react';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import StepRankingTable from '../components/StepRankingTable';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import LOGO from '../assets/images/logo-incentive-lyreco.svg';
import LOGOS_STEP_1 from '../assets/images/regate-ecosse.png';
import LOGOS_STEP_2 from '../assets/images/route-des-couleurs.png';

const LOGOS = {
    1: LOGOS_STEP_1,
    2: LOGOS_STEP_2
};

const TEXT_1 = {
    1: 'Du 3 Juin au 19 Juillet',
    2: 'Du 2 Septembre au 31 Octobre'
};

const TEXT_2 = {
    1: 'L’étape La Régate d’Écosse est terminée',
    2: 'L’étape La Route des Couleurs est terminée'
};

function SummerBreakPage() {
    const { currentUser, isAuth } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);
    const navigate = useNavigate();

    const [players, setPlayers] = useState([]);

    useEffect(() => {
        if (isAuth()) {
            socket.on('server:client:get-players-rank', (data) => {
                if (data.error) {
                    console.log(data.error);
                }
                else {
                    setPlayers(data);
                }
            });
            socket.emit('client:server:get-players-rank');

            return () => {
                socket.off('server:client:get-players-rank');
            };
        }
        else {
            navigate('/');
        }
    }, []);

    return (
        <Layout>
            <main className='summer-break'>
                <div className='top'>
                    <div className='img1'>
                        <img src={LOGO} width={183} />
                    </div>

                    <div className='img2'>
                        <img src={LOGOS[currentUser?.currentStep]} width={79} />
                    </div>

                    <div className='txt1'>
                        {TEXT_1[currentUser?.currentStep]}
                    </div>

                    <div className='txt2'>
                        {TEXT_2[currentUser?.currentStep]}
                    </div>
                </div>

                <div>
                    {currentUser?.currentStep === 1 && players.length > 0 && <StepRankingTable dataPlayers={players} stepTable={1} />}
                    {currentUser?.currentStep === 2 && players.length > 0 && <StepRankingTable dataPlayers={players} stepTable={2} />}
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default SummerBreakPage;
