import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import Layout from '../components/Layout';

import LOGO from '../assets/images/logo-incentive-lyreco.svg';
import LOGO_LYRECO from '../assets/images/logo-lyreco.svg';
import LOGO_3M from '../assets/images/logo-3m.svg';
import Footer from '../components/Footer';

function InscriptionStep1Page() {
    const { isAuth, currentUser, login } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [redirectLogin, setRedirectLogin] = useState(false);

    useEffect(() => {
        socket.on('server:client:inscription-step-1', (data) => {
            if (data.error) {
                setErrorMessage(data.error);
            }
            else {
                login({ ...data, gameTime: { duration: 600 } });
                setRedirect(true);
            }
        });

        const storedPseudo = localStorage.getItem('pseudo');
        const storedPassword = localStorage.getItem('password');

        if (storedPseudo && storedPassword && !isAuth()) {
            setRedirectLogin(true);
        }

        return () => {
            socket.off('server:client:inscription-step-1');
        };
    }, []);

    const validateEmail = (email) => {
        // Regex pour valider le format général de l'email
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        // Liste des noms de domaine valides
        const validDomains = [
            'bruneau.fr',
            'mmm.com',
            'thegobeliners.com',
            'hop-cs.fr',
            'highlab.fr',
            'hop-highlab.fr'
        ];

        // Vérifie le format général de l'email
        if (!re.test(String(email).toLowerCase())) {
            return false;
        }

        // Extrait le nom de domaine de l'email
        const emailDomain = email.split('@')[1];

        // Vérifie si le domaine de l'email fait partie de la liste des domaines valides
        return validDomains.includes(emailDomain);
    };

    const validatePhone = (phone) => {
        const re = /^(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{1,4}\)?[-.\s]?)?(?:\d{1,4}[-.\s]?){1,3}\d{1,4}$/;
        return re.test(phone);
    };

    const _submit = () => {
        setErrorMessage('');

        if (firstname === '') {
            setErrorMessage('Veuillez renseigner votre prénom');
        }
        else if (lastname === '') {
            setErrorMessage('Veuillez renseigner votre nom');
        }
        else if (!validateEmail(email)) {
            setErrorMessage('Veuillez renseigner un email valide');
        }
        else if (!validatePhone(phone)) {
            setErrorMessage('Veuillez renseigner un numéro de téléphone valide');
        }
        else {
            const user = { firstname, lastname, email, phone };

            socket.emit('client:server:inscription-step-1', user);
        }
    };

    if (redirect || (currentUser && currentUser.snowmobile)) {
        return <Navigate to='/inscription/step-2' />;
    }

    if (redirectLogin) {
        return <Navigate to='/login' />;
    }

    return (
        <Layout>
            <main className='inscription inscription-1'>
                <img src={LOGO} width={183} className='img-logo' />

                <div className='popin'>
                    <div className='title'>Indiquer son identité</div>

                    <input type='text' className='firstname' placeholder='Prénom' value={firstname} onChange={(e) => setFirstname(e.target.value)} autoComplete='off' />
                    <input type='text' className='lastname' placeholder='Nom' value={lastname} onChange={(e) => setLastname(e.target.value)} autoComplete='off' />
                    <input type='email' className='email' placeholder='Adresse e-mail' value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' />
                    <input type='tel' className='phone' placeholder='Numéro de téléphone' value={phone} onChange={(e) => setPhone(e.target.value)} autoComplete='off' />

                    {errorMessage !== '' &&
                        <div className='txt-error text-danger'>{errorMessage}</div>
                    }

                    <button onClick={_submit}>Suivant</button>

                    <Link to='/login'>J'ai déjà un compte</Link>
                </div>

                <div className='logos'>
                    <img src={LOGO_LYRECO} width={103} />

                    <img src={LOGO_3M} width={61} />
                </div>

                <Footer />
            </main>
        </Layout>
    );
}

export default InscriptionStep1Page;
