import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';

import NotFoundPage from '../pages/NotFoundPage';
import LoginPage from '../pages/LoginPage';
import ForgottenPasswordPage from '../pages/ForgottenPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import GamePage from '../pages/GamePage';
import InstructionsPage from '../pages/InstructionsPage';
import LegalNoticePage from '../pages/LegalNoticePage';
import CguPage from '../pages/CguPage';
import RankingPage from '../pages/RankingPage';
import InscriptionStep1Page from '../pages/InscriptionStep1Page';
import InscriptionStep2Page from '../pages/InscriptionStep2Page';
import InscriptionStep3Page from '../pages/InscriptionStep3Page';

import PrivateRoute from '../utils/PrivateRoute';
import ValidateAccountPage from '../pages/ValidateAccountPage';
import SummerBreakPage from '../pages/SummerBreakPage';

function AllRoutes() {
    return (
        <Router>
            <Routes>
                <Route path='/login' element={<LoginPage />} />
                <Route exact path='/inscription/step-1' element={<InscriptionStep1Page />} />
                <Route exact path='/mentions-legales' element={<LegalNoticePage />} />
                <Route exact path='/cgu' element={<CguPage />} />
                <Route exact path='/pause' element={<SummerBreakPage />} />
                <Route exact path='/password-request' element={<ForgottenPasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path='/validate-account' element={<ValidateAccountPage />} />
                <Route path='/validate-account/:token' element={<ValidateAccountPage />} />

                <Route path='/' element={<PrivateRoute />}>
                    <Route exact path='/' element={<GamePage />} />
                    <Route exact path='/instructions' element={<InstructionsPage />} />
                    <Route exact path='/classement' element={<RankingPage />} />
                    <Route exact path='/inscription/step-2' element={<InscriptionStep2Page />} />
                    <Route exact path='/inscription/step-3' element={<InscriptionStep3Page />} />
                </Route>

                <Route path='*' element={<NotFoundPage />} />
            </Routes>
        </Router>
    );
}

export default AllRoutes;
