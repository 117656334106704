import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

import LOGO from '../assets/images/logo-incentive-lyreco.svg';
import LOGO_LYRECO from '../assets/images/logo-lyreco.svg';
import LOGO_3M from '../assets/images/logo-3m.svg';

function ForgottenPasswordPage() {
    const { isAuth, login } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSucessMessage] = useState('');
    const [mailIsSent, setMailIsSent] = useState(false);

    useEffect(() => {
        socket.on('server:client:reset-password', (data) => {
            if (data.error) {
                setErrorMessage(data.error);
            }
            else {
                setSucessMessage('Vous allez recevoir un mail avec un lien de réinitialisation pour votre mot de passe.');
                setMailIsSent(true);
            }
        });

        return () => {
            socket.off('server:client:reset-password');
        };
    }, [login, socket]);

    const _submit = () => {
        setErrorMessage('');

        if (!validateEmail(email)) {
            setErrorMessage('Adresse email invalide');
            return;
        }

        socket.emit('client:server:reset-password', email);
    };

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };

    if (isAuth()) {
        return <Navigate to='/' />;
    }
    else {
        return (
            <Layout>
                <main className='login'>
                    <img src={LOGO} width={183} className='img-logo' alt='Logo Incentive Lyreco' />

                    <div className='popin'>
                        <div className='title'>Réinitialiser mon mot de passe</div>

                        {!mailIsSent && (
                            <input type='email' className='login' placeholder='Adresse e-mail' value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' />
                        )}

                        {errorMessage && <div className='desc-password text-danger'>{errorMessage}</div>}
                        {successMessage && <div className='desc-password text-success'>{successMessage}</div>}

                        {!mailIsSent && (
                            <button onClick={_submit}>Envoyer</button>
                        )}

                        <Link to='/login'>Se connecter</Link>
                    </div>

                    <div className='logos'>
                        <img src={LOGO_LYRECO} width={103} alt='Logo Lyreco' />
                        <img src={LOGO_3M} width={61} alt='Logo 3M' />
                    </div>

                    <Footer />
                </main>
            </Layout>
        );
    }
}

export default ForgottenPasswordPage;
