import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import Moment from 'react-moment';
import moment from 'moment';

import { CurrentUserContext } from '../_contexts/CurrentUserContext';
import { SocketContext } from '../_contexts/SocketContext';
import { SERVER_URL } from '../utils/config';
import TopBar from '../components/TopBar';
import Layout from '../components/Layout';
import Map from '../components/Map';
import Footer from '../components/Footer';

import PICTO_RANKING from '../assets/images/picto-classement.svg';
import PICTO_HORLOGE from '../assets/images/picto-horloge.svg';
import PICTO_MEDIAS from '../assets/images/picto-medias.svg';
import PICTO_INSTRUCTIONS from '../assets/images/picto-instructions.svg';
import PICTO_CODE from '../assets/images/picto-code.svg';
import PICTO_LOGOUT from '../assets/images/picto-logout.svg';
import LINE from '../assets/images/line.svg';
import PICTO_SPEED from '../assets/images/picto-speed.svg';
import PICTO_ASSISTANCE from '../assets/images/picto-assistance.svg';
import PICTO_AUTO_PILOTE from '../assets/images/picto-auto-pilote.svg';
import PICTO_ENERGIE from '../assets/images/picto-energie.svg';
import PICTO_VIDEO from '../assets/images/picto-video.svg';
import PICTO_PDF from '../assets/images/picto-pdf.svg';
import PICTO_PPT from '../assets/images/picto-ppt.svg';
import PICTO_URL from '../assets/images/picto-url.svg';
import PICTO_CROSS from '../assets/images/picto-cross.svg';
import LOGOS_STEP_1 from '../assets/images/regate-ecosse.png';
import LOGOS_STEP_2 from '../assets/images/route-des-couleurs.png';
import PICTO_AIDE from '../assets/images/picto-aide.svg';
import PICTO_EMAIL from '../assets/images/picto-email.svg';

const LOGOS = {
    1: LOGOS_STEP_1,
    2: LOGOS_STEP_2
};

function GamePage() {
    const { currentUser, logout, updatePlayer } = useContext(CurrentUserContext);
    const { socket } = useContext(SocketContext);

    const [assistanceIsActive, setAssistanceIsActive] = useState(currentUser.callSupportAt !== null);

    const [medias, setMedias] = useState([]);
    const [showPopinMedias, setShowPopinMedias] = useState(false);

    const [QCM, setQCM] = useState(null);
    const [showPopinQCM, setShowPopinQCM] = useState(false);
    const [hasAnswerQCM, setHasAnswerQCM] = useState(false);
    const [hasGoodAnswerQCM, setHasGoodAnswerQCM] = useState(null);
    const [goodAnswerQCM, setGoodAnswerQCM] = useState(null);
    const [showGoodAnswerQCM, setShowGoodAnswerQCM] = useState(false);
    const [valueQCM, setValueQCM] = useState(null);

    const [showPopinCode, setShowPopinCode] = useState(false);
    const [showPopinCodeValide, setShowPopinCodeValide] = useState(false);
    const [valueCode, setValueCode] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [messageCode, setMessageCode] = useState('');

    const [showPopinAide, setShowPopinAide] = useState(false);

    useEffect(() => {
        socket.on('server:client:code', (data) => {
            if (data.error) {
                setErrorCode(data.error);
            }
            else {
                const validCodes = ['GO!BRUNEAU'];
                const codes = {
                    1: [
                        'GO!BRUNEAU'
                    ],
                    2: [
                        'GO!BRUNEAU'
                    ]
                };

                if (validCodes.includes(valueCode.trim().toUpperCase())) {
                    if (codes[currentUser.currentStep].includes(valueCode.trim().toUpperCase())) {
                        updatePlayer({ isStarted: true });
                    }
                }

                setShowPopinCode(false);
                setShowPopinCodeValide(true);
                setMessageCode(data.message);
            }
        });

        socket.on('server:client:call-support', (data) => {
            if (data.error) {
                console.log(data.error);
            }
            else {
                updatePlayer(data.user);
                setAssistanceIsActive(true);
            }
        });

        socket.on('server:client:enable-auto-pilot', (data) => {
            if (data.error) {
                console.log(data.error);
            }
            else {
                updatePlayer(data);
            }
        });

        socket.on('server:client:disable-auto-pilot', (data) => {
            if (data.error) {
                console.log(data.error);
            }
            else {
                updatePlayer(data);
            }
        });

        socket.on('server:client:get-question', (data) => {
            if (data) {
                setQCM(data);

                setShowPopinQCM(true && currentUser.isStarted);
            }
        });
        socket.emit('client:server:get-question');

        socket.on('server:client:submit-question', (data) => {
            if (data.hasGoodAnswer) {
                setValueCode(data.code);
            }

            setHasGoodAnswerQCM(data.hasGoodAnswer);
            setGoodAnswerQCM(data.goodAnswer);
            setShowGoodAnswerQCM(true);
        });

        socket.on('server:client:get-medias', (data) => {
            if (data) {
                setMedias(data);
            }
        });
        socket.emit('client:server:get-medias');

        if (!currentUser.isStarted) {
            setShowPopinCode(true);
        }

        return () => {
            socket.off('server:client:my-rank');
            socket.off('server:client:code');
            socket.off('server:client:call-support');
            socket.off('server:client:enable-auto-pilot');
            socket.off('server:client:disable-auto-pilot');
            socket.off('server:client:get-question');
            socket.off('server:client:submit-question');
        };
    }, [currentUser, valueCode]);

    const _togglePopinMedias = () => {
        if (!showPopinCode && !showPopinCodeValide) {
            setShowPopinMedias(!showPopinMedias);
        }
    };

    const _togglePopinCode = () => {
        if (!showPopinMedias && !showPopinCodeValide) {
            setShowPopinCode(!showPopinCode);
        }
    };

    const _submitQCM = () => {
        if (valueQCM != null) {
            setHasAnswerQCM(true);

            socket.emit('client:server:submit-question', valueQCM);
        }
    };

    const _submitCode = () => {
        if (valueCode !== '') {
            setShowPopinQCM(false);
            setErrorCode('');
            setMessageCode('');

            socket.emit('client:server:code', { code: valueCode, uuid: currentUser.uuid });
        }
    };

    const _closePopinCodeValide = () => {
        setShowPopinCodeValide(false);
        setValueCode('');
        setErrorCode('');
        setMessageCode('');
    };

    const _callSupport = () => {
        if (currentUser.isStuck === true && assistanceIsActive === false) {
            socket.emit('client:server:call-support', { uuid: currentUser.uuid, currentStep: currentUser.currentStep });
        }
    };

    const _toggleAutoPilot = () => {
        if (!currentUser.isAutoPilot) {
            socket.emit('client:server:enable-auto-pilot', { uuid: currentUser.uuid, step: currentUser.currentStep });
        }
        else {
            socket.emit('client:server:disable-auto-pilot', { uuid: currentUser.uuid, step: currentUser.currentStep });
        }
    };

    const _sendMediaStat = (media) => {
        const data = {
            playerId: currentUser.id,
            stat: `media|${currentUser.currentStep}|${currentUser.type}|${media.titre}`
        };

        socket.emit('client:server:stat-media', data);
    };

    const now = new moment();
    const startAt = new moment(currentUser.autoPilotAt);
    const diff = now.diff(startAt, 'seconds');
    const countdownAutoPilotActive = Date.now() + (currentUser.autoPilotDuration - diff) * 1000;
    const countdownAutoPilotInactive = Date.now() + currentUser.autoPilotDuration * 1000;

    const isWeekendAutoPilot = (now.day() === 5 && now.hour() >= 18) || // Vendredi après 18h
        (now.day() === 6) || // Samedi
        (now.day() === 0 && now.hour() < 9); // Dimanche avant 9h00

    if (currentUser.inSession === false) {
        return <Navigate to='/classement' />;
    }

    if (currentUser.snowmobile === null) {
        return <Navigate to='/inscription/step-3' />;
    }

    return (
        <Layout>
            <main className='game'>
                <Map hasQuiz={showPopinQCM} />

                <TopBar />

                <img src={LOGOS[currentUser.currentStep]} width={79} className='img-logo' />

                <button className='btn-aide' onClick={() => setShowPopinAide(true)}>
                    <img src={PICTO_AIDE} width={37} />
                </button>

                <div className='bottom'>
                    <div className='hud'>
                        <div className='line'>
                            <img src={LINE} width={116} />
                        </div>

                        <div className='speed'>
                            <div className={`left cadre-speed ${currentUser.colorPixel}`} style={{ boxShadow: `0 0 10px 5px ${currentUser.colorPixel}` }}>
                                <img src={PICTO_SPEED} width={18} />

                                <span>{Math.round(currentUser.speed > 150 ? currentUser.speed / 100 : currentUser.speed)} nœuds</span>
                            </div>

                            {currentUser.codes && currentUser.codes.length > 0 &&
                                <div className='right'>
                                    <img src={PICTO_ENERGIE} width={19} />

                                    <div className='bar'>
                                        <div className='inner' style={{ width: `${currentUser.timeCodeRemaining}%` }}></div>
                                    </div>
                                </div>
                            }
                        </div>

                        {currentUser.isStuck === true && (
                            <div className={`assistance ${assistanceIsActive === true ? 'active' : ''}`}>
                                <button onClick={_callSupport}>
                                    <img src={PICTO_ASSISTANCE} width={36} />

                                    <span>
                                        {assistanceIsActive === true ? 'Assistance contactée' : 'Demander assistance'}
                                    </span>
                                </button>

                                <div className='infos'>
                                    <span className='label'>
                                        Intervention dans
                                    </span>

                                    <img src={PICTO_HORLOGE} width={14} />

                                    <div className='timer'>
                                        <Moment interval={1000} duration={new moment()} date={new moment(currentUser.callSupportAt).add(6, 'hours')} format='HH:mm' />
                                    </div>
                                </div>
                            </div>
                        )}

                        {isWeekendAutoPilot ?
                            (
                                <div className={`auto-pilote ${currentUser.isAutoPilot === true ? 'active' : ''}`}>
                                    <button>
                                        <img src={PICTO_AUTO_PILOTE} width={22} />

                                        <span>
                                            Auto-pilote week-end
                                        </span>
                                    </button>
                                </div>
                            )
                            :
                            (
                                <>
                                    {!currentUser.isStuck && currentUser.isStarted && currentUser.autoPilotDuration > 0 && (
                                        <div className={`auto-pilote ${currentUser.isAutoPilot === true ? 'active' : ''}`}>
                                            <button onClick={_toggleAutoPilot}>
                                                <img src={PICTO_AUTO_PILOTE} width={22} />

                                                <span>
                                            {currentUser.isAutoPilot === true ? 'Désactiver auto-pilote' : 'Enclencher auto-pilote'}
                                        </span>
                                            </button>

                                            <div className='infos'>
                                                <img src={PICTO_HORLOGE} width={14} />

                                                <div className='timer'>
                                                    {currentUser.isAutoPilot ?
                                                        <Countdown
                                                            date={countdownAutoPilotActive}
                                                            renderer={({ days, hours, minutes, seconds, completed }) => {
                                                                if (completed) {
                                                                    _toggleAutoPilot();

                                                                    return <span>'00:00'</span>;
                                                                }
                                                                else {
                                                                    const H = days * 24 + hours;

                                                                    return <span>{H.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span>;
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <Moment interval={1000} duration={new moment()} date={new moment(countdownAutoPilotInactive)} format='HH:mm:ss' />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )
                        }
                    </div>

                    <div className='menu'>
                        <button onClick={_togglePopinMedias}>
                            <img src={PICTO_MEDIAS} width={33} />

                            <span>Médias</span>
                        </button>

                        <Link to='/instructions'>
                            <img src={PICTO_INSTRUCTIONS} width={34} />

                            <span>Instructions</span>
                        </Link>

                        <button onClick={_togglePopinCode}>
                            <img src={PICTO_CODE} width={28} />

                            <span>Code</span>
                        </button>

                        <Link to='/classement'>
                            <img src={PICTO_RANKING} width={32} />

                            <span>Classement</span>
                        </Link>

                        <button onClick={logout}>
                            <img src={PICTO_LOGOUT} width={25} />

                            <span>Déconnexion</span>
                        </button>
                    </div>

                    <Footer />
                </div>

                {showPopinMedias === true && (
                    <div className='popin-medias'>
                        <div onClick={() => setShowPopinMedias(false)} className='close-popin'>
                            <img src={PICTO_CROSS} width={13} />
                        </div>

                        <div className='title'>
                            Les médias de l’étape
                        </div>

                        <div className='medias'>
                            {medias.map((m, index) => (
                                <a key={index} className='media' href={m.extern ? m.file : `${SERVER_URL}/${m.file}`} target='_blank' onClick={() => _sendMediaStat(m)}>
                                    {m.type === 'video' && <img src={PICTO_VIDEO} width={34} />}

                                    {m.type === 'pdf' && <img src={PICTO_PDF} width={34} />}

                                    {m.type === 'ppt' && <img src={PICTO_PPT} width={34} />}

                                    {m.type === 'url' && <img src={PICTO_URL} width={34} />}

                                    <span>{m.titre}</span>
                                </a>
                            ))}
                        </div>
                    </div>
                )}

                {showPopinQCM === true && hasAnswerQCM === false && showGoodAnswerQCM === false && (
                    <div className='popin-qcm'>
                        <div onClick={() => setShowPopinQCM(false)} className='close-popin'>
                            <img src={PICTO_CROSS} width={13} />
                        </div>

                        <div className='title'>
                            Obtenez 15% de vitesse en répondant à cette question :
                        </div>

                        <div className='question'>
                            {QCM.question}
                        </div>

                        <div className='answers'>
                            {QCM.answers.map((a, index) => (
                                <div key={index} className='answer'>
                                    <input type='radio' checked={valueQCM === a} onChange={(e) => setValueQCM(e.target.value)} name='answer' id={`answer-${index}`} value={a} />

                                    <label htmlFor={`answer-${index}`}>
                                        {a}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <button onClick={_submitQCM}>
                            Valider ma réponse
                        </button>
                    </div>
                )}

                {showPopinQCM === true && hasAnswerQCM === true && showGoodAnswerQCM === true && (
                    <div className='popin-qcm'>
                        <div onClick={() => setShowGoodAnswerQCM(false)} className='close-popin'>
                            <img src={PICTO_CROSS} width={13} />
                        </div>

                        <div className='title'>
                            Obtenez 15% de vitesse en répondant à cette question :
                        </div>

                        <div className='question'>
                            {QCM.question}
                        </div>

                        <div className='answers'>
                            {QCM.answers.map((a, index) => (
                                <div key={index} className='answer'>
                                    <input disabled={true} type='radio' checked={valueQCM === a} name='answer' id={`answer-${index}`} value={a} />

                                    <label htmlFor={`answer-${index}`} className={`${goodAnswerQCM === a ? 'good' : 'bad'}`}>
                                        {a}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <button onClick={() => setShowGoodAnswerQCM(false)}>
                            Suivant
                        </button>
                    </div>
                )}

                {showPopinQCM === true && hasAnswerQCM === true && hasGoodAnswerQCM === true && showGoodAnswerQCM === false && (
                    <div className='popin-qcm good'>
                        <div onClick={_submitCode} className='close-popin'>
                            <img src={PICTO_CROSS} width={13} />
                        </div>

                        <div className='title-2'>
                            Bravo, bonne réponse !
                        </div>

                        <img src={PICTO_ENERGIE} width={58} />

                        <div className='label'>
                            Voici un code BOOSTER 25%
                        </div>

                        <div className='code'>
                            {valueCode}
                        </div>

                        <button onClick={_submitCode}>
                            Appliquer le code
                        </button>
                    </div>
                )}

                {showPopinQCM === true && hasAnswerQCM === true && hasGoodAnswerQCM === false && showGoodAnswerQCM === false && (
                    <div className='popin-qcm wrong'>
                        <div onClick={() => setShowPopinQCM(!showPopinQCM)} className='close-popin'>
                            <img src={PICTO_CROSS} width={13} />
                        </div>

                        <div className='title-2'>
                            Mauvaise réponse !
                        </div>

                        <button onClick={() => setShowPopinQCM(!showPopinQCM)}>
                            Fermer
                        </button>
                    </div>
                )}

                {showPopinCode === true && (
                    <div className='popin-code'>
                        <div onClick={() => setShowPopinCode(false)} className='close-popin'>
                            <img src={PICTO_CROSS} width={13} />
                        </div>

                        {errorCode !== '' &&
                            <div className='error text-danger'>
                                {errorCode}
                            </div>
                        }

                        <div className='title'>
                            Entrer un code
                        </div>

                        <input type='text' value={valueCode} onChange={(e) => setValueCode(e.target.value)} autoComplete='off' />

                        <button onClick={_submitCode}>
                            Soumettre
                        </button>
                    </div>
                )}

                {showPopinCodeValide === true && (
                    <div className='popin-code-valide'>
                        <div onClick={_closePopinCodeValide} className='close-popin'>
                            <img src={PICTO_CROSS} width={13} />
                        </div>

                        <img src={PICTO_ENERGIE} width={58} />

                        <div className='title' dangerouslySetInnerHTML={{ __html: messageCode }}></div>

                        <button onClick={_closePopinCodeValide}>
                            Fermer
                        </button>
                    </div>
                )}

                {showPopinAide === true && (
                    <div className='popin-aide'>
                        <div onClick={() => setShowPopinAide(false)} className='close-popin'>
                            <img src={PICTO_CROSS} width={13} />
                        </div>

                        <div className='title'>
                            AIDE
                        </div>

                        <div className='text'>
                            N’hésitez pas à nous contacter en cas de besoin :
                        </div>

                        <div className='buttons'>
                            {/*<a className='whatsapp' href='https://wa.me/613753718' target='_blank'>*/}
                            {/*    <img src={PICTO_WHATSAPP} width={13} />*/}

                            {/*    <span>Whatsapp</span>*/}
                            {/*</a>*/}

                            <a className='email' href='mailto:assistance@inc-regatta.fr' target='_blank'>
                                <img src={PICTO_EMAIL} width={13} />

                                <span>Envoyer un mail</span>
                            </a>
                        </div>
                    </div>
                )}

                <div className={`cadre ${currentUser.colorPixel}`} style={{ boxShadow: `inset 0 0 10px 5px ${currentUser.colorPixel}` }}></div>
            </main>
        </Layout>
    );
}

export default GamePage;
